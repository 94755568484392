define("discourse/plugins/discourse-lexicon-plugin/discourse/templates/deeplink", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <h1>{{ i18n 'deeplink.title' }}</h1>
  <button
    type="button"
    id="open-in-lexicon-app"
    class="btn btn-primary"
    {{action (route-action 'open' @model.link)}}
  >
    {{#if @model.is_pm }}
      {{ i18n 'deeplink.button_label_view_message' }}
    {{else}}
      {{ i18n 'deeplink.button_label_view_post' }}
    {{/if}}
  </button>
  
  */
  {
    "id": "C/IyShAQ",
    "block": "[[[10,\"h1\"],[12],[1,[28,[35,0],[\"deeplink.title\"],null]],[13],[1,\"\\n\"],[11,\"button\"],[24,1,\"open-in-lexicon-app\"],[24,0,\"btn btn-primary\"],[24,4,\"button\"],[4,[38,1],[[30,0],[28,[37,2],[\"open\",[30,1,[\"link\"]]],null]],null],[12],[1,\"\\n\"],[41,[30,1,[\"is_pm\"]],[[[1,\"    \"],[1,[28,[35,0],[\"deeplink.button_label_view_message\"],null]],[1,\"\\n\"]],[]],[[[1,\"    \"],[1,[28,[35,0],[\"deeplink.button_label_view_post\"],null]],[1,\"\\n\"]],[]]],[13],[1,\"\\n\"]],[\"@model\"],false,[\"i18n\",\"action\",\"route-action\",\"if\"]]",
    "moduleName": "discourse/plugins/discourse-lexicon-plugin/discourse/templates/deeplink.hbs",
    "isStrictMode": false
  });
});